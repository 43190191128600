import React, { useMemo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import Grid from '@mui/material/Unstable_Grid2';
import { Player } from 'common/components/Player';
import type { Procedure } from 'common/types/circulator';
import { CirculatorPTZHome } from './CirculatorPTZHome';
import { DynamicCameraPublisher } from './DynamicCameraPublisher';

const styles = {
    card: {
        backgroundColor: grey[200],
    },
    feed: {
        height: '145px',
    },
    title: {
        whiteSpace: 'ellipsis',
    },
    hidden: {
        display: 'none',
    },
};
// @ts-ignore
const useStyles = makeStyles(styles);

interface Props {
    procedure: Procedure;
}

const ptzWaitSeconds = (index: number) => {
    if (index === 0) {
        return 0;
    }

    return index + 2;
};

export const CirculatorPlayerPanel: React.FC<Props> = ({ procedure }) => {
    const classes = useStyles();
    const { isShadeDown: obscure } = procedure;
    const { streams } = procedure.device;

    const audioOnlyPublishStreams = useMemo(
        () =>
            streams.filter(stream => {
                return !stream.isFeatured && stream.type === 'AUDIO_ONLY' && stream.procedureId === procedure.id;
            }),
        [procedure.id, streams]
    );

    const videoStreams = useMemo(
        () =>
            streams
                .filter(stream => {
                    return !stream.isFeatured && stream.type.includes('VIDEO');
                })
                .sort((a, b) => a.order - b.order),
        [streams]
    );

    const columnWidth = useMemo(() => {
        const count = videoStreams.length;

        if (count <= 6) {
            return 4;
        }

        return 3;
    }, [videoStreams]);

    const gridSize = useMemo(() => {
        const count = videoStreams.length;

        if (count <= 6) {
            return 24;
        }

        return count * 3;
    }, [videoStreams]);

    return (
        <>
            <Grid container direction="row" alignItems="center" spacing={1} columns={gridSize}>
                {videoStreams.map((stream, index) => (
                    <Grid xs={columnWidth} key={stream.id}>
                        <Typography className={classes.title}>{stream.name}</Typography>
                        {!stream.procedureId ? (
                            <>
                                <Player
                                    className={classes.feed}
                                    src={stream.lowResSrc || stream.src}
                                    iceServers={JSON.stringify(procedure.device.iceServers)}
                                    obscure={obscure}
                                    muted
                                />
                                <CirculatorPTZHome stream={stream} waitSeconds={ptzWaitSeconds(index)} />
                            </>
                        ) : (
                            <DynamicCameraPublisher procedure={procedure} stream={stream} />
                        )}
                    </Grid>
                ))}
            </Grid>
            {audioOnlyPublishStreams.map(stream => (
                <DynamicCameraPublisher procedure={procedure} stream={stream} key={stream.id} />
            ))}
        </>
    );
};
