import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Panel from 'client/components/UI/Panel';
import { useListDoctors } from 'client/hooks/doctors/useListDoctors';
import PurchaseOptionsDialog from 'common/components/UI/PurchaseOptionsDialog';
import { useGetProtectedObject } from 'common/hooks/storage/useGetProtectedObject';
import { Facility } from 'common/types';
import { Doctor, FacilityDoctor } from 'common/types/doctor';
import { isNullOrUndefined } from 'common/utils/ObjectUtils';
import companyPlaceholder from 'img/company-placeholder.png';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DoctorCard } from './DoctorCard';

// @ts-ignore
const styles = theme => ({
    root: {
        textAlign: 'left',
        color: theme.palette.primary.main,
        padding: theme.spacing(1.5),
    },
    boxPaper: {
        padding: '15px',
        borderRadius: '10px',
    },
    textSecondary: {
        color: '#ff5722',
        fontSize: '12px',
    },
    txtAddress: {
        marginTop: '10px',
    },
    boxDoctorAvatarHolder: {
        width: '200px',
        height: '200px',
        background: '#bbb',
        borderRadius: '50%',
        border: '1px solid #bbb',
    },
    boxDoctorChipRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        '& .MuiChip-root': {
            background: '#cee2f3',
            padding: '7px 7px',
            color: '#618db3',
            fontSize: '12px',
        },
    },
    websiteLink: {
        color: '#ff5722',
        textDecoration: 'none',
        cursor: 'pointer',
        fontSize: '12px',
    },
    txtCounter: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '12px',
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '15px',
    },
    imgAvatar: {
        width: '200px',
        height: '150px',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#eee',
        '& img': {
            width: '100%',
            height: '150px',
        },
    },
    darkText: {
        color: theme.palette.secondary.contrastText,
    },
    blueLink: {
        color: theme.palette.info.main,
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textDecoration: 'none',
    },
});

// @ts-ignore
const useStyles = makeStyles(styles);

interface FacilityItemProps {
    facility: Facility;
}

const FacilityItem = ({ facility }: FacilityItemProps) => {
    const classes = useStyles();
    const facilityImage = useGetProtectedObject(facility.photoUrl);
    const facilityAvatar = facility.photoUrl ? facilityImage : companyPlaceholder;
    const facilityHistory = facility.bio.substring(1, facility.bio.length < 150 ? facility.bio.length : 150);
    return (
        <>
            <Box className={classes.imgAvatar}>
                {facilityAvatar ? (
                    <Link to={`/facilities/${facility.id}`} style={{ width: '200px', height: '100%' }} title={facility?.name}>
                        <img src={facilityAvatar} alt={facility?.name} style={{ width: '200px', height: '100%' }} />
                    </Link>
                ) : (
                    <CircularProgress className={classes.loader} />
                )}
            </Box>
            <Box sx={{ flex: 'auto', padding: '0 10px', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" component="h5" className={classes.darkText}>
                    {facility.name}
                </Typography>
                <Typography variant="caption">
                    {facility.streetAddress ?? ''}&nbsp;{facility.city},&nbsp;{facility.state}&nbsp;
                    {facility.zip}
                </Typography>
                <Typography variant="caption" className={classes.textSecondary}>
                    {facility.phone ?? ''}
                </Typography>
                <Box display="flex" flexDirection="row" mb={2} sx={{ marginBottom: 'auto' }}>
                    {facility.websiteUrl && (
                        <a href={facility.websiteUrl} target="_blank" rel="noopener noreferrer external" className={classes.websiteLink}>
                            {facility.websiteUrl}
                        </a>
                    )}
                </Box>
                <Box sx={{ flex: 1, fontSize: '11px', color: '#8a98a3', marginTop: '15px' }}>
                    <span>
                        <strong>History :</strong> {facilityHistory}...{' '}
                        <Link to={`/facilities/${facility.id}`} color="info" className={classes.blueLink} title={`Read More about ${facility?.name}`}>
                            Read More
                        </Link>
                    </span>
                </Box>
                <Box sx={{ position: 'relative', marginTop: '15px' }}>
                    <PurchaseOptionsDialog facility={facility} isPrimary={false} />
                </Box>
            </Box>
        </>
    );
};

const DoctorInfo = () => {
    const { id } = useParams<{ id: string }>();
    const classes = useStyles();
    const navigate = useNavigate();
    const { reset } = useQueryErrorResetBoundary();
    const [doctor, setDoctor] = useState<Doctor>();
    const { doctors } = useListDoctors({ name: '', specialty: '' });

    useEffect(() => {
        if (isNullOrUndefined(id)) navigate(-1);
        const result = doctors.find(d => d.id === Number(id));
        if (result) {
            setDoctor(result);
        }
    }, [id, navigate, doctor, doctors]);

    return (
        <ErrorBoundary
            onReset={reset}
            // eslint-disable-next-line react/no-unstable-nested-components
            fallbackRender={({ resetErrorBoundary }) => (
                <Panel>
                    <p>Oops! Something went wrong...</p>
                    <Button onClick={() => resetErrorBoundary()} variant="contained" aria-label="Save">
                        Try again
                    </Button>
                </Panel>
            )}
        >
            <div className={classes.root}>
                <React.Suspense fallback={<Skeleton />}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Paper className={classes.boxPaper}>{doctor && <DoctorCard doctor={doctor} showFullBio={true} />}</Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper className={classes.boxPaper}>
                                {doctor?.facilityDoctors?.map((facilityDoctors: FacilityDoctor, index: number) => (
                                    <Box mr="1" key={index} sx={{ display: 'flex' }}>
                                        <FacilityItem facility={facilityDoctors.Facility} />
                                    </Box>
                                ))}
                            </Paper>
                        </Grid>
                    </Grid>
                </React.Suspense>
            </div>
        </ErrorBoundary>
    );
};

export default DoctorInfo;
