import { config } from 'circulator/hooks/config';
import { Api } from 'common/utils/api';
import { useQuery } from 'react-query';

export const useGetActiveProcedure = () => {
    const { key, path } = config.procedure.active();

    const result = useQuery(key, () => Api.get({ path }), {});

    return {
        procedure: result.data,
        isLoading: result.isLoading,
        isFetching: result.isFetching,
        isError: result.isError,
    };
};
