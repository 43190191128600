import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';
import { IceServer } from 'common/types';

/**
 * MasterType is used to determine the type of master stream that is being published.
 */
export const MasterType = {
    MOBILE_CIRCULATOR: 'mobile-circulator',
    CIRCULATOR_DEFAULT: 'circulator-default',
    CIRCULATOR_DYNAMIC: 'circulator-dynamic',
    FEATURED: 'featured',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MasterType = typeof MasterType[keyof typeof MasterType];

const deviceIdRegex = /^Dynamic-Device-(.*)--(.*)-\d+$/;

type MediaConstraints =
    | undefined
    | {
          video: MediaTrackConstraints | boolean;
      }
    | { audio: MediaTrackConstraints | boolean }
    | {
          video: MediaTrackConstraints | boolean;
          audio: MediaTrackConstraints | boolean;
      };

export interface MasterControllerProps {
    localViewId: string;
    streamId: string;
    publishUrl: string;
    token: string;
    iceServers: IceServer[];
    isPublishingVideo?: boolean;
    isPublishingAudio?: boolean;
    // this is used to block the ability to get or enable a video device
    // setting isPublishingVideo to true if canPublishVideo was set to false is likely to fail
    canPublishVideo?: boolean;
    audioDevice?: { id?: string; type: string };
    videoDevice?: { id?: string; type: string };
    masterType: MasterType;
}

export class MasterController {
    webRTCAdaptor: typeof WebRTCAdaptor;
    streamId: string;
    token: string;
    audio: boolean = false;
    video: boolean = false;
    masterType: MasterType;

    constructor(props: MasterControllerProps) {
        this.masterType = props.masterType;

        let mediaConstraints: MediaConstraints;

        if (this.masterType === MasterType.CIRCULATOR_DEFAULT) {
            mediaConstraints = {
                video: false,
                audio: true,
            };
        } else if (this.masterType === MasterType.CIRCULATOR_DYNAMIC) {
            console.log(`starting dynamic device publish for streamId ${props.streamId}`);

            const match = props.streamId.match(deviceIdRegex);

            if (!match) {
                console.error('unable to find audio or video device ids from streamId');

                mediaConstraints = {
                    video: false,
                    audio: false,
                };
            } else {
                const audioId = match[1];
                const videoId = match[2];

                console.log(`found audioId ${audioId} and videoId ${videoId}`);

                mediaConstraints = {
                    video: videoId ? { deviceId: videoId } : false,
                    audio: audioId ? { deviceId: audioId } : false,
                };
            }
        } else if (this.masterType === MasterType.FEATURED) {
            mediaConstraints = {
                video: props.canPublishVideo,
                audio: true,
            };
        } else if (this.masterType === MasterType.MOBILE_CIRCULATOR) {
            mediaConstraints = {
                video: {
                    facingMode: 'environment',
                    frameRate: { max: 30 },
                    aspectRatio: { exact: 1.7777777778 },
                    height: { min: 480, ideal: 1080, max: 1080 },
                    width: { min: 640, ideal: 1920, max: 1920 },
                },
                audio: true,
            };
        }

        this.streamId = props.streamId;
        this.token = props.token;

        this.audio = props.isPublishingAudio ?? false;
        this.video = props.isPublishingVideo ?? false;

        this.webRTCAdaptor = new WebRTCAdaptor({
            websocket_url: props.publishUrl,
            mediaConstraints: mediaConstraints,
            peerconnection_config: {
                iceServers: props.iceServers,
            },
            sdp_constraints: {
                OfferToReceiveAudio: false,
                OfferToReceiveVideo: false,
            },
            localVideoId: props.localViewId,
            bandwidth: 600, //int | string, default is 900 kbps, string can be 'unlimited'
            dataChannelEnabled: false,
            debug: true,
            callback: (info: any, obj: any) => {
                console.debug('[MASTER] callback', info, obj);
                if (info === 'publish_started' || info === 'session_restored') {
                    try {
                        if (!this.video) {
                            this.webRTCAdaptor.turnOffLocalCamera();
                        }
                        if (!this.audio) {
                            this.webRTCAdaptor.muteLocalMic();
                        }
                    } catch (error) {
                        console.error('[MASTER] Error turning off camera and mic', error);
                    }
                }
            },
            callbackError: function (error: any, message: any) {
                console.error('[MASTER] callbackError', error, message);
            },
        });
    }

    start() {
        console.debug('[MASTER] Starting');

        // this delay was added because in some cases firefox would not properly initialize the
        // publish until initialize was done or other previous sockets had been closed
        setTimeout(() => {
            if (this.webRTCAdaptor) {
                this.webRTCAdaptor.publish(this.streamId, this.token);
            }
        }, 2000);
    }

    addVideo(): Promise<unknown> {
        console.debug('[MASTER] Adding video');

        return this.webRTCAdaptor.turnOnLocalCamera();
    }

    removeVideo(): Promise<unknown> {
        console.debug('[MASTER] Removing video');

        return this.webRTCAdaptor.turnOffLocalCamera();
    }

    addAudio(): Promise<unknown> {
        console.debug('[MASTER] Adding audio');

        return this.webRTCAdaptor.unmuteLocalMic();
    }

    removeAudio(): Promise<unknown> {
        console.debug('[MASTER] Removing audio');

        return this.webRTCAdaptor.muteLocalMic();
    }

    stop() {
        console.debug('[MASTER] Stopping');

        this.webRTCAdaptor.stop(this.streamId);
        this.webRTCAdaptor.closeStream();
        this.webRTCAdaptor.closeWebSocket();
        this.webRTCAdaptor = null;
    }
}
