import { useEffect } from 'react';
import { Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { useGetActiveProcedure } from 'circulator/hooks/procedure/useGetActiveProcedure';
import { BackgroundWrapper } from 'mobile/components/UI/BackgroundWrapper';
import useMobileLayouts from 'mobile/hooks/useMobileLayouts';
import useMobileStyles from 'mobile/hooks/useMobileStyles';
import { useNavigate } from 'react-router-dom';
import CutsLogo from '../../img/cuts-logo.png';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
        padding: '0 40px !important',
    },
    subtitle: {
        fontSize: '1.5rem',
        fontWeight: 200,
        textAlign: 'center',
    },
    subtitle2: {
        fontSize: '1rem',
        fontWeight: 200,
    },
    semibold: {
        fontWeight: 400,
    },
    gap: {
        gap: '20px',
    },
}));

export const CreateOrArchive = () => {
    const classes = useStyles();
    const { button } = useMobileStyles();
    const layouts = useMobileLayouts();
    const navigate = useNavigate();
    const { procedure, isLoading } = useGetActiveProcedure();

    useEffect(() => {
        if (procedure) {
            navigate('/mobile/shot');
        }
    }, [procedure, navigate]);

    return (
        <BackgroundWrapper backButtonVisible={true} backButtonHref="/mobile" headerImage={CutsLogo}>
            {isLoading && <CircularProgress size={20} />}
            {!isLoading && (
                <div className={layouts.verticalContainer}>
                    <div className={layouts.padded}>
                        <Typography className={classes.subtitle}>
                            Welcome to
                            <br />
                            <span className={classes.semibold}>VSURGIC CUTS</span>,
                            <br />
                            your personal video
                            <br />
                            archive repository.
                        </Typography>
                        <Typography className={`${classes.subtitle}`} style={{ marginTop: '20px' }}>
                            Please choose from
                            <br /> the following options:
                        </Typography>
                    </div>
                    <div className={layouts.dynamicContainer}>
                        <div className={layouts.verticalContainer}>
                            <Button variant="contained" className={`${button} ${classes.gap}`} onClick={() => navigate('/mobile/start-shot')}>
                                Create
                            </Button>
                        </div>
                        <div className={layouts.verticalContainer}>
                            <Button variant="contained" className={button} onClick={() => navigate('/mobile/archives')}>
                                Archives
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </BackgroundWrapper>
    );
};
