export const config = {
    procedure: {
        all: () => ['procedure'],
        active: () => ({
            key: [...config.procedure.all(), 'active'],
            path: `/procedures/me/active`,
        }),
        start: () => ({
            path: `/procedures`,
        }),
        stop: (procedureId: number) => ({
            path: `/procedures/${procedureId}`,
        }),
    },
    mobile: {
        all: () => ['mobile'],
        getDevice: () => ({
            key: [...config.mobile.all(), 'get-device'],
            path: `/procedures/get-device`,
        }),
    },
    doctors: {
        all: () => ['doctors'],
        get: () => ({
            key: [...config.doctors.all(), 'get'],
            path: '/doctors/get',
        }),
    },
    facilities: {
        all: () => ['facilities'],
        list: () => ({
            key: [...config.facilities.all(), 'list'],
            path: '/facilities',
        }),
        details: () => [...config.facilities.all(), 'detail'],
        detail: (facilityId: number) => ({
            key: [...config.facilities.details(), facilityId],
            path: `facilities/${facilityId}`,
        }),
        listAll: () => ({
            key: ['facilities', 'all'],
            path: '/facilities/all',
        }),
        listForCuts: () => ({
            key: ['facilities', 'cuts'],
            path: `/facilities/cuts`,
        }),
    },
    user: {
        all: () => ['user'],
        details: () => [...config.user.all(), 'detail'],
        detail: (userId: string) => ({
            key: [...config.user.details(), userId],
            path: `/users/${userId}`,
        }),
        me: () => [...config.user.all(), 'me'],
    },
    attendee: {
        all: () => ['attendee'],
        lists: () => [...config.attendee.all(), 'lists'],
        list: (procedureId: number) => ({
            key: [...config.attendee.lists(), procedureId],
            path: `/procedures/${procedureId}/attendees`,
        }),
        primaries: () => [...config.attendee.all(), 'primary'],
        primary: (procedureId: number | undefined) => ({
            key: [...config.attendee.primaries(), procedureId],
            path: `/procedures/${procedureId}/attendees/primary`,
        }),
        update: (procedureId: number, attendeeId: number) => ({
            path: `/procedures/${procedureId}/attendees/${attendeeId}`,
        }),
    },
};
