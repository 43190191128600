import React from 'react';
import { Box, Chip, makeStyles, Typography } from '@material-ui/core';
import { useGetProtectedObject } from 'common/hooks/storage/useGetProtectedObject';
import { Doctor } from 'common/types/doctor';
import profilePlaceholder from 'img/profile-placeholder.png';
import moment from 'moment';
import { Link } from 'react-router-dom';

// @ts-ignore
const styles = theme => ({
    facilityBoxSeparator: {
        borderRight: `1px solid ${theme.palette.primary.main}`,
    },
    relatedFacilities: {
        background: theme.palette.primary.light,
        color: theme.palette.primary.main,
    },
    doctorContentCard: {
        display: 'flex',
        height: '100%',
        marginBottom: theme.spacing(1),
    },
    filterButton: {
        padding: '0 0 0 5px',
        fontSize: '0.8rem',
        border: '1px solid #ddd',
        background: '#eee',
        borderRadius: 0,
        margin: 0,
        display: 'flex',
        justifyContent: 'space-between',
    },
    filterButtonCaret: {
        fontSize: '20px',
    },
    filterMenu: {
        fontSize: '0.8rem',
        padding: '5px 15px',
    },
    filterResult: {
        padding: '5px 5px',
        color: '#2b333f',
        fontFamily: 'sans-serif',
        fontWeight: 600,
        fontSize: '0.75rem',
    },
    boxDoctorAvatar: {
        width: '200px',
        height: '200px',
        background: '#bbb',
        borderRadius: '50%',
        border: '1px solid #bbb',
    },
    boxDoctorName: {
        color: '#333',
        fontWeight: '700',
        fontSize: '1.1rem',
        flex: '1',
    },
    boxDoctorScpecialty: {
        marginTop: '-20px',
    },
    boxDoctorChipRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        '& .MuiChip-root': {
            background: '#cee2f3',
            padding: '7px 7px',
            color: '#618db3',
            fontSize: '12px',
        },
    },
    boxDoctorBio: {
        margin: '10px 0',
        maxHeight: '120px',
        overflow: 'hidden',
    },
    boxDoctorFullBio: {
        margin: '10px 0',
    },
    websiteLink: {
        color: '#ff5722',
        textDecoration: 'none',
        cursor: 'pointer',
        fontSize: '12px',
    },
    dpMenu: {
        '& ul': {
            width: '120px',
        },
    },
    txtCounter: {
        fontSize: '15px',
    },
    textSecondary: {
        color: '#ff5722',
        fontSize: '15px',
    },
});
// @ts-ignore
const useStyles = makeStyles(styles);

interface DoctorCardProps {
    doctor: Doctor;
    goToFacilityDetails?: (facilityId: number) => void;
    showFullBio?: boolean;
}

export const DoctorCard = ({ doctor, goToFacilityDetails, showFullBio }: DoctorCardProps) => {
    const classes = useStyles();
    const doctorPhoto = useGetProtectedObject(doctor.photoUrl);
    const doctorAvatar = doctorPhoto ?? profilePlaceholder;

    return (
        <Box sx={{ display: 'flex', padding: '15px' }} key={doctor.id}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                <Link to={`/doctors/${doctor.id}`}>
                    <img src={doctorAvatar} alt={doctor.name} className={classes.boxDoctorAvatar} />
                </Link>
            </Box>
            <Box sx={{ display: 'flex', padding: '0 15px 0 30px', flex: 'auto', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ flex: 'auto' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box className={classes.boxDoctorName} sx={{ flex: 'auto' }}>
                                {doctor.name}
                            </Box>
                            <Box
                                sx={{
                                    padding: '10px',
                                    border: '1px solid #dadfe1',
                                    borderRadius: '15px',
                                    display: 'flex',
                                    flex: 'auto',
                                    maxWidth: '60%',
                                }}
                            >
                                <Box sx={{ flex: 'auto' }}>
                                    <strong className={classes.txtCounter}>All: </strong>
                                    <strong className={classes.textSecondary}>{doctor?.procedures?.length ?? 0}</strong>
                                </Box>
                                <Box sx={{ flex: 'auto' }}>
                                    <strong className={classes.txtCounter}>This Week: </strong>
                                    <strong className={classes.textSecondary}>
                                        {doctor?.procedures?.filter(x => moment(x.createdAt) >= moment().add(-1, 'w')).length ?? 0}
                                    </strong>
                                </Box>
                                <Box sx={{ flex: 'auto' }}>
                                    <strong className={classes.txtCounter}>This Month: </strong>
                                    <strong className={classes.textSecondary}>
                                        {doctor?.procedures?.filter(x => moment(x.createdAt) >= moment().add(-1, 'M')).length ?? 0}
                                    </strong>
                                </Box>
                                <Box sx={{ flex: 'auto' }}>
                                    <strong className={classes.txtCounter}>This Year: </strong>
                                    <strong className={classes.textSecondary}>
                                        {doctor?.procedures?.filter(x => moment(x.createdAt) >= moment().add(-1, 'y')).length ?? 0}
                                    </strong>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.boxDoctorScpecialty}>
                            <Typography variant="caption">{doctor.specialty}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" mb={2}>
                            {doctor.websiteUrl && (
                                <Box display="flex" flexDirection="row">
                                    <a href={doctor.websiteUrl} target="_blank" rel="noopener noreferrer external" className={classes.websiteLink}>
                                        {doctor.websiteUrl}
                                    </a>
                                </Box>
                            )}
                        </Box>
                        <Box className={classes.boxDoctorChipRoot}>
                            {doctor.surgeryTypes.map((type: string) => (
                                <Chip size="small" label={type} />
                            ))}
                        </Box>
                    </Box>
                </Box>

                <Typography variant="caption" paragraph className={!showFullBio ? classes.boxDoctorBio : classes.boxDoctorFullBio}>
                    <strong>Biography </strong>
                    {doctor.bio}
                </Typography>
            </Box>
        </Box>
    );
};
