import React, { useState } from 'react';
import {
    Avatar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    makeStyles,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { ProcedureNotesDialog } from 'circulator/components/Procedure/ProcedureNotesDialog';
import { useGetProtectedObject } from 'common/hooks/storage/useGetProtectedObject';
import type { Procedure } from 'common/types/circulator';
import { BusinessIcon, CloseIcon, DeviceIcon, DoctorIcon, PatientIcon, ProcedureIcon } from 'common/utils/Icons';

interface Props {
    procedure: Procedure;
    onStopProcedure: () => void;
}

export const ProcedureSummary: React.FC<Props> = ({ procedure, onStopProcedure }) => {
    const classes = useStyles();

    const { doctor, title, patientInitials, companyName, deviceName, brandName } = procedure ?? {};
    const doctorPhoto = useGetProtectedObject(doctor?.photoUrl);
    const [openNotes, setOpenNotes] = useState(false);
    const [isStopping, setIsStopping] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleOnStop = () => {
        setIsDialogOpen(true);
    };

    const handleConfirm = () => {
        setIsStopping(true);
        onStopProcedure();
    };

    const handleCancel = () => {
        setIsStopping(false);
        setIsDialogOpen(false);
    };

    return (
        <Grid container className={classes.root}>
            <div className={classes.item}>
                <Avatar src={doctorPhoto ?? ''}>
                    <DoctorIcon />
                </Avatar>
                <Typography variant="body2">{doctor?.name}</Typography>
            </div>
            <div className={classes.collection}>
                {[
                    { title: 'Procedure', icon: <ProcedureIcon />, value: title },
                    { title: 'Patient', icon: <PatientIcon />, value: patientInitials },
                    { title: 'Company', icon: <BusinessIcon />, value: companyName },
                    { title: 'Device', icon: <DeviceIcon />, value: deviceName },
                    { title: 'Brand', icon: <ProcedureIcon />, value: brandName && `${brandName} ™` },
                ]
                    .filter(({ value }) => value)
                    .map(({ title, icon, value }) => (
                        <div className={classes.item} key={title}>
                            <Tooltip title={title} arrow>
                                <span>{icon}</span>
                            </Tooltip>
                            <Typography variant="body2">{value}</Typography>
                        </div>
                    ))}
            </div>
            <Button className={classes.end} variant="contained" onClick={handleOnStop} color="primary" startIcon={<CloseIcon />}>
                End Procedure
            </Button>
            <ProcedureNotesDialog procedure={procedure} enabled={openNotes} onClose={() => setOpenNotes(false)} />
            <Dialog open={isDialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle>
                    <Typography variant="h6" component="h6" display="block" gutterBottom>
                        End Procedure
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText color="primary">
                        <Typography variant="caption" display="block" gutterBottom>
                            Are you sure you want to end this procedure?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} variant="outlined" size="small" disabled={isStopping} className="modal-cancel-button">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} autoFocus variant="contained" size="small" disabled={isStopping}>
                        Confirm
                        {isStopping && (
                            <>
                                &nbsp;&nbsp;
                                <CircularProgress size={15} thickness={8} color="primary" />
                            </>
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: 'center',
        background: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1, 4),
        padding: theme.spacing(2),
    },
    item: {
        alignItems: 'center',
        display: 'flex',
        gap: theme.spacing(2),
    },
    collection: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
        flex: '1 1 0',
    },
    end: {
        margin: 'auto',
    },
}));
