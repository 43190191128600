import { makeStyles } from '@material-ui/core';
import { useMaster } from 'common/hooks/WebRTC';
import { MasterType } from 'common/hooks/WebRTC/master-controller';
import { Stream } from 'common/types';
import type { Procedure } from 'common/types/circulator';
import noVideo from 'img/video-placeholder.jpg';
import { AttendeeToggleButton } from '../AttendeeToggleButton';
import { MuteButton } from '../MuteButton';
import { VideoToggleButton } from '../VideoToggleButton';

export interface VideoPlayerProps {
    procedure: Procedure;
    toggleDrawer: (open?: boolean) => void;
    hasAttendees?: boolean;
}

export interface SoundPlayerProps {
    audioStream: Stream;
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flex: 1,
        maxHeight: '100%',
        maxWidth: '100%',
        background: 'black',
        position: 'relative',
        width: '100%',
    },
    video: {
        maxWidth: '100%',
        maxHeight: '100%',
        background: 'black',
        flex: '1 1 auto',
    },
}));

const videoElementId = 'local-video-output';

export const VideoPlayer: React.FC<VideoPlayerProps> = props => {
    const classes = useStyles();

    const { controller } = useMaster({
        videoElementId,
        streamId: props.procedure.publishStreamId!,
        publishUrl: props.procedure.publishUrl!,
        token: props.procedure.publishToken!,
        iceServers: props.procedure.publishIceServers!,
        isPublishingAudio: true,
        isPublishingVideo: true,
        canPublishVideo: true,
        masterType: MasterType.MOBILE_CIRCULATOR,
    });

    return (
        <div className={classes.container}>
            <video className={classes.video} id={videoElementId} autoPlay playsInline poster={noVideo} muted />
            <VideoToggleButton controller={controller} />
            <MuteButton controller={controller} />
            <AttendeeToggleButton toggleDrawer={props.toggleDrawer} hasAttendees={!!props.hasAttendees} />
        </div>
    );
};
